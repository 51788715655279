<template>
  <div class="card">
    <div class="card-header">
      <div class="text-center">
        <div class="font-size-18 mb-3">
          <i :class="['fa', 'fa-home']" />
          {{ $t("profil.Control") }}
        </div>
      </div>
    </div>
    <div class="card-body">
      <a-divider class="m-0">{{ $t("profil.nomUser") }}:</a-divider>
      <p class="text-gray-5 mb-2">
        {{ userName }}
      </p>
      <a-divider>{{ $t("profil.mdp") }}:</a-divider>
      <a-button-group class="d-flex justify-content-center">
        <a-button @click="showModal"> {{ $t("action.modifier") }} </a-button>
        <a-popconfirm
          :title="$t('sms.alertNumberSMS')"
          :ok-text="$t('action.confirmer')"
          :cancel-text="$t('action.annuler')"
          @confirm="() => sendSmsLoginToParent()"
        >
          <a-button type="primary" :loading="loadingSms" :disabled="loadingSms">
            {{ $t("menu.envoyerSms") }}
          </a-button>
        </a-popconfirm>
      </a-button-group>
    </div>
    <a-modal
      v-model="visible"
      :title="$t('profil.changerPrametreParent')"
      :ok-button-props="{
        props: { disabled: hasErrors(form.getFieldsError()) },
      }"
      @ok="handleSubmit"
    >
      <a-form
        layout="inline"
        :form="form"
        @submit="handleSubmit"
        class="login-form"
        style="width: 100%"
      >
        <a-form-item
          :validate-status="userNameError() ? 'error' : ''"
          :help="userNameError() || ''"
          style="width: 100%"
        >
          <a-input
            style="width: 100%"
            v-decorator="[
              'userName',
              {
                initialValue: userName,
                rules: [
                  {
                    required: true,
                    message: $t('requis.username'),
                  },
                  {
                    validator: validateNoWhitespace,
                    message: $t('requis.noWhitespace'),
                  },
                ],
              },
            ]"
            :placeholder="$t('profil.nomUser')"
          >
            <a-icon
              slot="prefix"
              type="user"
              style="color: rgba(0, 0, 0, 0.25)"
            />
          </a-input>
        </a-form-item>
        <a-form-item
          :validate-status="passwordError() ? 'error' : ''"
          :help="passwordError() || ''"
          style="width: 100%"
        >
          <a-input
            v-decorator="[
              'password',
              {
                initialValue: pwd,
                rules: [
                  { required: true, message: $t('requis.mdp') },
                  {
                    min: 4,
                    message: $t('requis.carac'),
                  },
                  {
                    validator: validateNoWhitespace,
                    message: $t('requis.noWhitespace'),
                  },
                ],
              },
            ]"
            :placeholder="$t('profil.mdp')"
          >
            <a-icon
              slot="prefix"
              type="lock"
              style="color: rgba(0, 0, 0, 0.25)"
            />
          </a-input>
        </a-form-item>
        <vue-qr
          :text="qrData"
          logoSrc="/favicon.png"
          :callback="getQrImage"
          qid="student"
        ></vue-qr>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
/* eslint-disable */
import apiClient from "@/services/axios";
import { mapState } from "vuex";
import JsPDF from "jspdf";
import VueQr from "vue-qr";

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some((field) => fieldsError[field]);
}
export default {
  name: "controlParentale",
  computed: {
    ...mapState(["settings"]),
  },
  components: {
    VueQr,
  },
  props: {
    idParent: {
      required: false,
    },
    userName: {
      required: false,
    },
    pwd: {
      required: false,
    },
    phones: {
      required: false,
    },
  },
  beforeMount() {
    this.qrData = btoa(
      this.idParent +
        "." +
        this.settings.activeBuilding +
        "." +
        this.settings.base_url
    );
  },
  methods: {
    validateNoWhitespace(rule, value, callback) {
      if (/\s/.test(value)) {
        callback(new Error("Username cannot contain whitespace"));
      } else {
        callback();
      }
    },
    showModal() {
      this.visible = true;
    },
    getQrImage(dataUrl) {
      this.qrDataImage = dataUrl;
    },
    handleOk(e) {
      this.visible = false;
    },
    userNameError() {
      const { getFieldError, isFieldTouched } = this.form;
      return getFieldError("userName");
    },
    // Only show error after a field is touched.
    passwordError() {
      const { getFieldError, isFieldTouched } = this.form;
      return getFieldError("password");
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (err) console.log(err);
        else {
          apiClient
            .patch("/parents/password/" + this.idParent, values)
            .then(async () => {
              this.userName = values.userName;
              this.$message.success(this.$t("success.parentMSA"));
            })
            .catch((e) => {
              this.$message.error(e.response.data.error.message);
            });
        }
        this.generatePdf(values);
      });

      this.visible = false;
      this.form.resetFields();
    },
    generatePdf(values) {
      let arrayOfImages = this.settings.image;
      for (var i = 0; i < arrayOfImages.length; i++) {
        if (this.settings.activeBuilding === arrayOfImages[i].db) {
          var imgData = new Image();
          imgData.src = arrayOfImages[i].logo;
        }
      }
      const doc = new JsPDF();
      doc.setFont("Amiri");
      var date = new Date();
      var titre = "Informations de connexion";
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      doc.setFontSize(15);
      doc.text(80, 70, titre);
      doc.setFontSize(10);
      doc.addImage(imgData, "JPEG", 8, 8, 40, 40);
      doc.setFontSize(15);
      doc.text(150, 40, date.toLocaleDateString("fr-FR", options));
      doc.setFontSize(12);
      doc.text(40, 100, "Nom d'utilisateur : " + values.userName);
      doc.text(40, 120, "Mot de passe : " + values.password);
      doc.text(
        40,
        140,
        "Vous pouvez s'identifier en utlisant ce Qr code dans l'application mobile :"
      );

      doc.addImage(this.qrDataImage, "JPEG", 80, 150, 45, 45);

      //end page numbering
      doc.save(this.userName + ".pdf");
    },
    sendSmsLoginToParent() {
      //this.loadingSms = true;
      let parent = {
        _id: this.idParent,
        userName: this.userName,
        pp: btoa(this.pwd),
        father: { phone: this.phones.father },
        mother: { phone: this.phones.mother },
      };
      apiClient
        .put("/sms/loginparents", {
          target: ["father", "mother"],
          customNumbers: "",
          total: 2,
          tags: ["Logins parents"],
          parents: [parent],
        })
        .then((res) => {
          this.$message.success("SMS envoyé avec succeés");
        })
        .catch((e) => {
          console.log(e);
          switch (e.response.status) {
            case 401:
              this.$message.warning(e.response.data.message);

              break;

            default:
              this.$message.error(e.response.data.message);

              break;
          }
        })
        .finally(() => (this.loadingSms = false));
    },
  },
  mounted() {
    this.$nextTick(() => {
      // To disabled submit button at the beginning.
      this.form.validateFields();
    });
  },
  data() {
    return {
      qrData: "",
      loadingSms: false,
      visible: false,
      hasErrors,
      qrDataImage: "",

      form: this.$form.createForm(this, { name: "parent_login" }),
    };
  },
};
</script>
<style>
.login-form .ant-col {
  width: 100% !important;
}
</style>
